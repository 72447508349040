import BaseAPI from '../BaseAPI';

class Cinemas extends BaseAPI {
  constructor(props) {
    super(props);
    this.baseUrl = '/cinemas';
  }

  /**
   * Gets a list of cinemas
   *
   * @returns {Promise<*|Error>}
   */

  fetchAll() {
    const url = this.baseUrl;

    return this.get({ url });
  }

  /**
   * Gets a certain cinema by the id
   *
   * @param id {string}
   * @returns {Promise<*|Error>}
   */
  fetchById(id) {
    const url = `${this.baseUrl}/${id}`;

    return this.get({ url });
  }

  fetchByIdSeasonSessions(cinemaId, AboIds) {
    const url = `/season-pass-deals/${cinemaId}/${AboIds}/movies`;

    
return this.get({ url });
  }

  fetchByIdSeasonScreens(cinemaId) {
    const url = `/season-pass-deals/${cinemaId}/screens`;

    
return this.get({ url });
  }

  /**
   * Gets a certain cinema by the id
   *
   * @param cinemaId {string}
   * @param params {Object}
   * @param params.date {string | undefined}
   * @returns {Promise<*|Error>}
   */
  fetchMoviesToCinema(cinemaId, params = {}) {
    const url = `${this.baseUrl}web/${cinemaId}/movies`;

    return this.get({ url, params });
  }

  /**
   * Gets a list of cinemas with the related movies
   *
   * @param params {Object}
   * @param params.date {string | undefined}
   * @returns {Promise<*|Error>}
   */
  fetchWithMovies(params = {}) {
    const url = `${this.baseUrl}web/with-movies`;
    const locationId = this.storage.getLocation();

    return this.get({ url, params: { ...params, locationId } });
  }

  fetchWithMoviesLocation(params = {}) {
    // const url = `${this.baseUrl}web/with-movies`;
    const url = `${this.baseUrl}/list/short`;
    // const locationId = this.storage.getLocation();
    // const locationId = params.locationId;

    return this.get({ url, params });
  }

  fetchSessions(id, params) {
    const url = `${this.baseUrl}/${id}/sessions`;

    return this.get({ url, params });
  }

  fetchTickets(cinemaId, sessionId) {
    const url = `${this.baseUrl}/${cinemaId}/sessions/${sessionId}/tickets`;

    return this.get({ url });
  }

  /**
   * Gets the pricelist by cinema id
   *
   * @param id {string}
   * @returns {Promise<*|Error>}
   */
  fetchPriceList(id) {
    const url = `${this.baseUrl}/${id}/price-list`;
    const get = this.withHTML(this.get);

    return get({ url });
  }

  /**
   * Toggles a cinema favorite state
   *
   * @param id {string}
   * @param favorite {boolean}
   * @returns {Promise<*|Error>}
   */
  toggleFavorite(id, favorite) {
    const url = `/users/favorites/cinemas`;
    const body = [
      {
        id,
        favorite
      }
    ];

    return this.post({ url, body });
  }
}

export default Cinemas;
