import React, { useEffect, useState } from 'react';
import { useConfig } from 'app/hooks';
import { useTranslation } from 'react-i18next';
import { Button } from 'app/components';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { BonusInfoWrapper } from 'app/modules/info/BonusCard/styles';
import { BlockTickets, Info, Page } from './styles';
import { useSelector } from 'react-redux';

const PickupScreen = ({ pickUpNumber, setPickUpNumber }) => {
  const [showPickupNumber, setShowPickupNumber] = useState(false);
  const pickUpNumberData = useSelector(state => state?.concessions?.pickUpNumber);
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const bookingNumber = location?.pathname?.split('/')[5];
  const cinemaId = location?.pathname?.split('/')[4];
  const {
    routes,
  } = useConfig();
  const handleHomePage = (data) => {
    if (data) {
      pickUpNumber(cinemaId, bookingNumber, 'later');
    }
    history.push(routes.movies.pattern);
  };

  useEffect(() => {
    setPickUpNumber(null);
  }, []);

  const handlePickupNow = () => {
    pickUpNumber(cinemaId, bookingNumber, 'now');
  };

  useEffect(() => {
    if (pickUpNumberData) {
      setShowPickupNumber(true);
    }
  }, [pickUpNumberData]);

  return (
    <Page>
      <BonusInfoWrapper>
        <div className="container">
          {showPickupNumber ?
            <BlockTickets>
              <Info>{t('common.pickup_successful_prepare')}</Info>
              <Info>{t('common.pickup_number')} : {pickUpNumberData?.PickupNumber}</Info>
              {/* <div className="b-bonus-card__button">
                <NavLink to={routes.movies.pattern} style={{ width: '100%' }}>
                  <Button isPrimary onClick={() => handleHomePage()} style={{ marginTop: '16px' }}>
                    {t('common.to_home_page')}
                  </Button>
                </NavLink>
              </div> */}
            </BlockTickets>
            :
            (!location?.state?.isPickupReady &&
              <>
                <h1>{t('common.pickup_prepare_title')}</h1>
                <div className="seasonPass__modal_btns">
                  <Button isDefault onClick={() => handlePickupNow()} style={{ textAlign: 'center', cursor: 'pointer', marginRight: '10px' }}>
                    {t('purchase.modals.pickup_popup.button_pickup_now')}
                  </Button>
                  <Button isDefault onClick={() => handleHomePage("pickupLater")}>
                    {t('purchase.modals.pickup_popup.button_pickup_later')}
                  </Button>
                </div>
              </>)
          }
        </div>
      </BonusInfoWrapper>
    </Page>
  )

}

export default PickupScreen;