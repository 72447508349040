import BaseAPI from '../BaseAPI';

class Concessions extends BaseAPI {
  constructor(props) {
    super(props);
    this.baseUrl = '/concessions';
  }

  /**
   * Gets a list of concessions
   *
   * @returns {Promise<*|Error>}
   */
  fetchAllCategories(cinemaId) {
    const url = `${this.baseUrl}/categories-new?cinemaId=${cinemaId}`;

    return this.get({ url });
  }

  // /**
  //  * Gets a certain cinema by the id
  //  *
  //  * @param id {string}
  //  * @returns {Promise<*|Error>}
  //  */
  fetchConcessionById(cinemaId, id) {
    const url = `${this.baseUrl}?cinemaId=${cinemaId}&categoryId=${id}`;

    return this.get({ url });
  }

  fetchComboConcessionById(cinemaId, id) {
    const url = `${this.baseUrl}/combo?cinemaId=${cinemaId}&categoryId=${id}`;

    return this.get({ url });
  }

  fetchComboItemConcessionById({ cinemaId, id }) {
    const url = `${this.baseUrl}/combo-items?cinemaId=${cinemaId}&id=${id}`;

    return this.get({ url });
  }

  fetchPickNumber(cinemaId, bookingNumber, pickup) {
    const url = `/users/bookings/prepare-concession/${cinemaId}/${bookingNumber}/${pickup}`;

    return this.get({ url });
  }

  orderConcessionItem(orderId, data) {
    const url = `/orders/${orderId}/concessions-on-server`;
    const body = {
      "orderConcessionItems": data
    }

    return this.post({ url, body });
  }

  orderConcessionCombo(orderId, concessionsData, data) {
    const url = `/orders/${orderId}/concessions-combo-on-server`;
    const body = {
      "orderConcessionItems": concessionsData,
      "orderComboConcessionItems": data
    }

    return this.post({ url, body });
  }

  removeConcessionItem(orderId) {
    const url = `/orders/${orderId}/remove-concessions`;

    return this.remove({ url });
  }
}

export default Concessions;