import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import useConfig from 'app/hooks/useConfig';
import RouterContextComponent from 'app/RouterContext';

import Layout from 'app/containers/Layout';
import Error from 'app/containers/Error';
import ErrorBoundary from 'app/containers/ErrorBoundary';
import NoInternet from 'app/containers/NoInternet';
import { useSelector, useDispatch } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import storage from 'app/services/session';
import technologiesModule from 'app/store/technologies';
import RefundTickets from './modules/auth/RefundTickets';


import { PreviousRouteProvider } from './context/previousRoute';
import BoxMenu from './modules/purchase/BoxMenu';
import ConcessionModal from './modules/purchase/ConcessionModal';
import Sessions from './modules/sessions';
import PickupScreen from './modules/purchase/PickupScreen';
// import Landing from './modules/landing/Landing15';

const { requestRedirectDomainURL } = technologiesModule.actions;

/**
 * Auth module
 */
const SignIn = lazy(() => import('app/modules/auth/SignIn'));
const SignUp = lazy(() => import('app/modules/auth/SignUp'));
const FfcSignUp = lazy(() => import('app/modules/auth/FfcSignUp/index'));
const PasswordReset = lazy(() => import('app/modules/auth/PasswordReset'));

const Movies = lazy(() => import('app/modules/movies'));
const Cinemas = lazy(() => import('app/modules/cinemas'));
const Events = lazy(() => import('app/modules/events'));

const Search = lazy(() => import('app/modules/search'));
const Account = lazy(() => import('app/modules/account'));
const Purchase = lazy(() => import('app/modules/purchase'));
const Info = lazy(() => import('app/modules/info'));
const Club = lazy(() => import('app/modules/club'));
const Technologies = lazy(() => import('app/modules/technologies'));
const CinemaRent = lazy(() => import('app/modules/cinemaRent'));
const CinemaAdvertising = lazy(() => import('app/modules/cinemaAdvertising'));
const Newsletter = lazy(() => import('app/modules/newsletter'));
const NewsletterVerify = lazy(() => import('app/modules/newsletterVerify'));
const Portrait = lazy(() => import('app/modules/portrait'));
const Contact = lazy(() => import('app/modules/contact'));
const Landing = lazy(() => import('app/modules/landing'));
const Survey = lazy(() => import('app/modules/survey'));

const propTypes = {
  sendVisitedScreens: PropTypes.func.isRequired
};

const Routes = ({ sendVisitedScreens }) => {
  const { routes } = useConfig();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      requestRedirectDomainURL()
    );
  }, []);

  const domainRedirectList = useSelector(state => state.technologies.domainRedirectURL);

  const currentUrl = window.location.origin + window.location.pathname + window.location.search

  const domainUrl = window.location.origin

  const matchedRule = domainRedirectList?.find(rule => rule?.domain == currentUrl);

  const matchedRule2 = domainRedirectList?.find(rule => rule?.domain == domainUrl);

  useEffect(() => {
    if (matchedRule) {
      window.location.replace(matchedRule.redirecturl);
    }
  }, [matchedRule]);

  useEffect(() => {
    if (matchedRule2) {
      window.location.replace(matchedRule2.redirecturl);
    }
  }, [matchedRule2]);

  return (
    <Router>
      <PreviousRouteProvider>
        <RouterContextComponent>
          <Layout listen={sendVisitedScreens}>
            <ErrorBoundary ErrorComponent={Error}>
              <Switch>
                <Route
                  path="/"
                  component={() => (
                    <Redirect
                      to={{
                        pathname: routes.movies.pattern
                      }}
                    />
                  )}
                  exact
                />
                <Route path={routes.login.pattern} component={SignIn} />
                <Route
                  path={routes.userRegisterFinish.pattern}
                  render={({ location }) => (
                    <Redirect
                      to={{
                        pathname: routes.login.pattern,
                        search: location.search,
                      }}
                    />
                  )}
                />
                <Route path={routes.registration.pattern} component={SignUp} />
                <Route path={routes.ffcSignUp.pattern} component={FfcSignUp} />
                <Route path={routes.resetPassword.pattern} component={PasswordReset} />
                <Route
                  path={routes.userPasswortAendern.pattern}
                  render={({ location }) => (
                    <Redirect
                      to={{
                        pathname: routes.resetPassword.pattern,
                        search: location.search,
                      }}
                    />
                  )}
                />
                <Route path={routes.refundTickets.pattern} component={RefundTickets} />

                <Route path={routes.movies.pattern} component={Movies} />
                <Route path={routes.cinemas.pattern} component={Cinemas} />
                <Route path={routes.events.pattern} component={Events} />
                <Route path={routes.boxMenu.pattern} component={BoxMenu} />
                <Route path={routes.concessionModal.pattern} component={ConcessionModal} />
                <Route path={routes.pickupSnacks.pattern} component={PickupScreen} />

                <Route path={routes.search.pattern} component={Search} />
                <Route path={routes.purchase.pattern} component={Purchase} />
                <Route path={routes.account.pattern} component={Account} />
                <Route path={routes.info.pattern} component={Info} />
                {process.env.REACT_APP_COUNTRY === 'aut' ? (
                  <Route path={routes.club.pattern} component={Club} />
                ) : null}
                <Route path={routes.technologies.pattern} component={Technologies} />
                <Route path={routes.cinemaRent.pattern} component={CinemaRent} />
                <Route path={routes.cinemaAdvertising.pattern} component={CinemaAdvertising} />
                <Redirect from={routes.newsletterRegisration.pattern} to={routes.newsletterEvent.pattern} exact />
                <Route path={routes.newsletterRegisrationForCinemaRent.pattern} component={Newsletter} />
                <Route path={routes.newsletterRegisrationVerify.pattern} component={NewsletterVerify} exact />
                <Route path={routes.portrait.pattern} component={Portrait} />
                <Route path={routes.faq.pattern} component={Contact} />
                <Route path={routes.sessions.pattern} component={Sessions} />
                <Route path={routes.landing.pattern} component={Landing} />
                <Route path={routes.survey.pattern} component={Survey} />
                <Route path={routes.no_internet.pattern} component={NoInternet} />
                <Route component={Error} />
              </Switch>
            </ErrorBoundary>
          </Layout>
        </RouterContextComponent>
      </PreviousRouteProvider>
    </Router>
  );
};

Routes.propTypes = propTypes;
export default Routes;
