import styled from 'styled-components';
import { withLinkUnderlined } from 'app/theme';

export default styled.div`
  display: flex;
  justify-content: space-between;
  // padding: 10.5px 0;
  border-top: ${({ theme, isxXtraCard }) => (isxXtraCard ? 'none' : `${theme.color.borderDark} solid 1px`)};
  border-bottom: ${({ theme, isxXtraCard }) => (isxXtraCard ? 'none' : `${theme.color.borderDark} solid 1px`)};
  align-items: center;
  margin-top: ${({ isSnacksAsideComponent }) => isSnacksAsideComponent ? '25px' : '0px'};
  padding: ${({ isSnacksAsideComponent }) => isSnacksAsideComponent ? '10px 16px' : '10.5px 0'};
  & > label {
    margin: 0;
    padding: 0;

    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1;
    cursor: pointer;
    user-select: none;
    color: ${({ theme }) => theme.color.textPrimary};

    & > .text {
      margin-left: 10px;
      vertical-align: middle;
    }

    & > .link {
      vertical-align: middle;
      margin-left: 5px;
      text-transform: lowercase;
      color: ${({ theme }) => theme.color.textPrimary};

      ${withLinkUnderlined}
    }
  }
`;
