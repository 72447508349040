export const ORDER_REQUEST = 'order/ORDER_REQUEST';
export const ORDER_INIT_REQUEST = 'order/INIT_REQUEST';
export const ORDER_TICKETS_REQUEST = 'order/TICKETS_REQUEST';

export const ORDER_SEASONPASS_TICKETS_REQUEST = 'order/SEASONPASS_TICKETS_REQUEST';
export const ORDER_SEASONPASS_TICKETS_PH2_REQUEST = 'order/SEASONPASS_TICKETS_PH2_REQUEST';

export const SET_ORDER = 'order/SET_ORDER';
export const CLEAR_ORDER = 'order/CLEAR_ORDER';

export const ORDER_CANCEL_REQUEST = 'order/CANCEL_REQUEST';

export const ORDER_CONCESSION_DIRECT_REQUEST = 'order/ORDER_CONCESSION_DIRECT_REQUEST';