import { from, of } from 'rxjs';
import { mergeMap, concatMap, catchError, startWith } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { setLoader, createToast, handleErrors } from './deps';
import {
  setLegalInfoContent,
  setImpressumContent,
  setB2bContent,
  setPriceListContent,
  setBonusCardInfoContent,
  setFamilyFilmClubContent,
  setBirthdayRoomContent,
  setCompetitionsContent,
  setSliderContent,
  successForSendFormData,
  competitionsDetailSuccess,
  competitionEventsSuccess,
  setFaqInfoContent,
  setLandingInfoContent,
  setHallInfoContent,
  setPriceListGastronomyContent,
  sendFaqsRegistrationSuccess,
  sendFooterNewsLetterRegistrationSuccess,
  successFfcNote,
  successMainUrl
} from './actions';
import {
  INFORMATION_LEGAL_REQUEST,
  INFORMATION_IMPRESSUM_REQUEST,
  INFORMATION_B2B_REQUEST,
  INFORMATION_PRICELIST_REQUEST,
  INFORMATION_BONUSCARD_REQUEST,
  INFORMATION_FAMILYFILMCLUB_REQUEST,
  INFORMATION_BIRTHDAYROOM_REQUEST,
  INFORMATION_COMPETITIONS_REQUEST,
  SLIDER_CONTENT_REQUEST,
  SEND_QUESTION_FORM_DATA,
  REQUEST_COMPETITION_DETAIL,
  COMPETITION_EVENTS_REQUEST,
  INFORMATION_FAQ_REQUEST,
  LANDING_INFO_REQUEST,
  HALLTICKET_BOOK_REQUEST,
  INFORMATION_PRICELIST_GASTRONOMY_REQUEST,
  FAQS_REGISTRATION_REQUEST,
  FOOTER_NEWSLETTER_REGISTRATION_REQUEST,
  GET_FFCSIGNUP_NOTE_REQUEST,
  GET_MAIN_URL_REQUEST,

} from './types';

export const fetchLegalInfo = ($action, $state, { api }) => {
  const $apiInformation = api.getModuleByName('information');

  return $action.pipe(
    ofType(INFORMATION_LEGAL_REQUEST),
    mergeMap(action =>
      from($apiInformation.fetchLegalInfo()).pipe(
        concatMap(res => [setLegalInfoContent(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),

        startWith(setLoader(true))
      )
    )
  );
};

export const fetchImpressum = ($action, $state, { api }) => {
  const $api = api.getModuleByName('information');

  return $action.pipe(
    ofType(INFORMATION_IMPRESSUM_REQUEST),
    mergeMap(action =>
      from($api.fetchImpressum()).pipe(
        concatMap(res => [setImpressumContent(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),

        startWith(setLoader(true))
      )
    )
  );
};

export const fetchB2b = ($action, $state, { api }) => {
  const $api = api.getModuleByName('information');

  return $action.pipe(
    ofType(INFORMATION_B2B_REQUEST),
    mergeMap(action =>
      from($api.fetchB2b()).pipe(
        concatMap(res => [setB2bContent(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),

        startWith(setLoader(true))
      )
    )
  );
};

export const fetchPriceList = ($action, $state, { api }) => {
  const $api = api.getModuleByName('technologies');

  return $action.pipe(
    ofType(INFORMATION_PRICELIST_REQUEST),
    mergeMap(action => {
      const { paramas } = action.payload;

      return from($api.fetchPriceListApi(paramas)).pipe(
        concatMap(res => [setPriceListContent(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),

        startWith(setLoader(true))
      );
    })
  );
};

export const fetchGastronomyPriceList = ($action, $state, { api }) => {
  const $api = api.getModuleByName('technologies');

  return $action.pipe(
    ofType(INFORMATION_PRICELIST_GASTRONOMY_REQUEST),
    mergeMap(action => {
      const { paramas } = action.payload;

      return from($api.fetchPriceGastronomyListApi(paramas)).pipe(
        concatMap(res => [setPriceListGastronomyContent(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),

        startWith(setLoader(true))
      );
    })
  );
};

export const fetchBonusCardInfo = ($action, $state, { api }) => {
  const $api = api.getModuleByName('information');

  return $action.pipe(
    ofType(INFORMATION_BONUSCARD_REQUEST),
    mergeMap(action => {
      return from($api.fetchBonusCardInfo()).pipe(
        concatMap(res => [setBonusCardInfoContent(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),

        startWith(setLoader(true))
      );
    })
  );
};

export const fetchFamilyFilmClub = ($action, $state, { api }) => {
  const $api = api.getModuleByName('information');

  return $action.pipe(
    ofType(INFORMATION_FAMILYFILMCLUB_REQUEST),
    mergeMap(action => {
      return from($api.fetchFamilyFilmClub()).pipe(
        concatMap(res => [setFamilyFilmClubContent(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),

        startWith(setLoader(true))
      );
    })
  );
};

export const fetchBirthdayRoom = ($action, $state, { api }) => {
  const $api = api.getModuleByName('information');

  return $action.pipe(
    ofType(INFORMATION_BIRTHDAYROOM_REQUEST),
    mergeMap(action => {
      return from($api.fetchBirthdayRoom()).pipe(
        concatMap(res => [setBirthdayRoomContent(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),

        startWith(setLoader(true))
      );
    })
  );
};

export const fetchCompetitionsInfo = ($action, $state, { api }) => {
  const $api = api.getModuleByName('competitions');

  return $action.pipe(
    ofType(INFORMATION_COMPETITIONS_REQUEST),
    mergeMap(action => {
      return from($api.fetchAll()).pipe(
        concatMap(res => [setCompetitionsContent(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),

        startWith(setLoader(true))
      );
    })
  );
};

export const fetchSliderContent = ($action, $state, { api }) => {
  const $api = api.getModuleByName('movies');

  return $action.pipe(
    ofType(SLIDER_CONTENT_REQUEST),
    mergeMap(action => {
      const { params } = action.payload;

      return from($api.fetchMovieDetails(params.id)).pipe(
        concatMap(res => [setSliderContent(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),

        startWith(setLoader(true))
      );
    })
  );
};

export const sendFormDataFunction = ($action, $state, { api }) => {
  const $api = api.getModuleByName('user');

  return $action.pipe(
    ofType(SEND_QUESTION_FORM_DATA),
    mergeMap(action => {
      const { params } = action.payload;

      return from($api.sendFormData(params)).pipe(
        concatMap(res => [successForSendFormData(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),

        startWith(setLoader(true))
      );
    })
  );
};

export const requestCompetitionData = ($action, $state, { api }) => {
  const $api = api.getModuleByName('user');

  return $action.pipe(
    ofType(REQUEST_COMPETITION_DETAIL),
    mergeMap(action => {
      const { params } = action.payload;

      return from($api.fetchCompetitionDetailContent(params.id)).pipe(
        concatMap(res => [competitionsDetailSuccess(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),

        startWith(setLoader(true))
      );
    })
  );
};

export const fetchCompetitionEvents = ($action, $state, { api }) => {
  const $api = api.getModuleByName('competitions');

  return $action.pipe(
    ofType(COMPETITION_EVENTS_REQUEST),
    mergeMap(action => {
      return from($api.fetchMainAll()).pipe(
        concatMap(res => [competitionEventsSuccess(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),

        startWith(setLoader(true))
      );
    })
  );
};

export const fetchFaqInfo = ($action, $state, { api }) => {
  const $api = api.getModuleByName('technologies');

  return $action.pipe(
    ofType(INFORMATION_FAQ_REQUEST),
    mergeMap(action => {
      return from($api.fetchFaqInfo()).pipe(
        concatMap(res => [setFaqInfoContent(res), setLoader(false)]),
        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),
        startWith(setLoader(true))
      );
    })
  );
};

export const fetchLandingnfo = ($action, $state, { api }) => {
  const $api = api.getModuleByName('technologies');

  return $action.pipe(
    ofType(LANDING_INFO_REQUEST),
    mergeMap(action => {
      const params = action?.payload?.paramas;

      return from($api.fetchLandingInfo({ params })).pipe(
        concatMap(res => [setLandingInfoContent(res), setLoader(false)]),
        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),
        startWith(setLoader(true))
      );
    })
  );
};

export const fetchHallInfo = ($action, $state, { api }) => {
  const $api = api.getModuleByName('technologies');

  return $action.pipe(
    ofType(HALLTICKET_BOOK_REQUEST),
    mergeMap(action => {
      const params = action?.payload?.params?.id;

      return from($api.fetchHallTicket({ params })).pipe(
        concatMap(res => [setHallInfoContent(res), setLoader(false)]),
        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),
        startWith(setLoader(true))
      );
    })
  );
};

export const saveFaqsRegistration = ($action, $state, { api }) => {
  const $apiNewsletter = api.getModuleByName('technologies');

  return $action.pipe(
    ofType(FAQS_REGISTRATION_REQUEST),
    mergeMap(action => {
      const {
        payload: { body }
      } = action;

      
return from($apiNewsletter.registration(body)).pipe(
        concatMap(res => [sendFaqsRegistrationSuccess(res), setLoader(false)]),
        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),
        startWith(setLoader(true))
      );
    })
  );
};

export const saveFooterNewsLetterRegistration = ($action, $state, { api }) => {
  const $apiNewsletter = api.getModuleByName('technologies');

  return $action.pipe(
    ofType(FOOTER_NEWSLETTER_REGISTRATION_REQUEST),
    mergeMap(action => {
      const {
        payload: { body }
      } = action;

      
return from($apiNewsletter.newsLetterRegistration(body)).pipe(
        concatMap(res => [sendFooterNewsLetterRegistrationSuccess(res), setLoader(false)]),
        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),
        startWith(setLoader(true))
      );
    })
  );
};

export const fetchFfcNote = ($action, $state, { api }) => {
  const $apiNewsletter = api.getModuleByName('technologies');

  return $action.pipe(
    ofType(GET_FFCSIGNUP_NOTE_REQUEST),
    mergeMap(action => {
      
      return from($apiNewsletter.getNote()).pipe(
        concatMap(res => [successFfcNote(res), setLoader(false)]),
        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),
        startWith(setLoader(true))
      );
    })
  );
};

export const getMainUrl = ($action, $state, { api }) => {
  const $apiNewsletter = api.getModuleByName('technologies');

  return $action.pipe(
    ofType(GET_MAIN_URL_REQUEST),
    mergeMap(action => {
      const { params } = action.payload;

      
return from($apiNewsletter.getMainUrl({params})).pipe(
        concatMap(res =>[successMainUrl(res), setLoader(false)]), 
        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),
        startWith(setLoader(true))
      );
    })
  );
};
