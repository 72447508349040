/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable array-callback-return  */
/* eslint-disable jsx-a11y/anchor-is-valid  */
import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useConfig } from "app/hooks";
import useUrlParams from "app/hooks/useUrlParams";
import { AFTER_ROUTE_TRANSITION } from "app/HOC/withRouteEvents";
import Header from "app/components/Header";
import Footer from "app/components/Footer";
import { Loader, MobileNav , Modal, ModalTypical, Button } from "app/components";
import Toasters from "app/containers/Toasters";

import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import Wrapper from "./styles";

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,

  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired,

  fetchcode: PropTypes.func.isRequired,
  code: PropTypes.string,

  children: PropTypes.element.isRequired,

  error: PropTypes.shape({
    code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    message: PropTypes.string,
    error: PropTypes.object
  }),
  cinemas: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,

  isLoading: PropTypes.bool.isRequired,
  userName: PropTypes.string,
  token: PropTypes.string,
  savedLocation: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  // isRespondedToCookiesAgreement: PropTypes.bool.isRequired,
  elementsVisibility: PropTypes.shape({
    header: PropTypes.bool.isRequired,
    footer: PropTypes.bool.isRequired
  }).isRequired,

  refreshToken: PropTypes.func.isRequired,
  fetchCinemas: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,
  saveLocation: PropTypes.func.isRequired,
  acceptCookiesAgreement: PropTypes.func.isRequired,
  declineCookiesAgreement: PropTypes.func.isRequired,
  listen: PropTypes.func.isRequired,
  legalInfo: PropTypes.arrayOf(
    PropTypes.shape({
      doc: PropTypes.string,
      docType: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  getLegalInfo: PropTypes.func.isRequired,
  sendFooterNewsletterRegistration: PropTypes.func.isRequired,
  newsLetterResult: PropTypes.object,
  newsLetterContent: PropTypes.array,
  QRcode: PropTypes.string
};

const defaultProps = {
  error: null,
  userName: null,
  token: null,
  savedLocation: null,
  isLoggedIn: null,
  code: null
};

const Layout = ({
  history: { push },
  location,

  children,
  error,
  cinemas,
  locations,

  isLoading,
  userName,
  user,
  token,
  savedLocation,
  isLoggedIn,
  // isRespondedToCookiesAgreement,
  elementsVisibility,

  refreshToken,
  fetchCinemas,
  getLocations,
  saveLocation,
  acceptCookiesAgreement,
  declineCookiesAgreement,
  listen,
  legalInfo,
  getLegalInfo,
  sendFooterNewsletterRegistration,
  newsLetterResult,
  newsLetterContent,
  footerNewsLetter,
  fetchcode,
  code,
  QRcode
}) => {
  const { pathname } = location;
  const { routes } = useConfig();
  const [isVisible, setVisibility] = useState(true);
  const [popupData, setPopupData] = useState(null);
  const [newsLetter, setNewsLetter] = useState([]);
  const { location: searchLocation } = useUrlParams("location");

  const [modal, setModal] = useState({
    isVisible: false,
    onClose: () => {},
    title: null,
    message: null
  });

  const [t] = useTranslation();

  useEffect(() => {
    if (searchLocation && savedLocation !== searchLocation) {
      saveLocation(searchLocation);
    }
  }, []);

  useEffect(() => {
    listen(location);

    const listener = e => listen(e.detail.currentLocation);

    window.addEventListener(AFTER_ROUTE_TRANSITION, listener, false);

    return () =>
      window.removeEventListener(AFTER_ROUTE_TRANSITION, listener, false);
  }, []);

  const [refreshingToken, setRefreshingToken] = useState(false);

  const requestTokenExpires = () => {
    const accessToken = localStorage.getItem("accessToken");

    if (refreshingToken) {
      return;
    }
    setRefreshingToken(true);
    if (accessToken) {
      // Your token expiration check logic here
      const decodetoken = jwtDecode(accessToken);
      const tokenExpiryTimeStamp = decodetoken.exp * 1000;
      const currentTimeStamp = Date.now();
      const thresold = 5 * 60 * 1000;
      const isTokenAboutToExpire =
        tokenExpiryTimeStamp - currentTimeStamp < thresold;

      if (isTokenAboutToExpire) {
        // Perform token refresh
        refreshToken();
        setRefreshingToken(false);
      } else {
        setRefreshingToken(false);
      }
    }
  };

  useEffect(() => {
    requestTokenExpires();
    const refreshToken = setInterval(requestTokenExpires, 10 * 60 * 1000);

    
return () => {
      clearInterval(refreshToken);
    };
  }, []);

  useEffect(() => getLegalInfo(), []);
  // on the request of client we have stop this
  // useEffect(() => {
  //   footerNewsLetter();
  // }, []);
  useEffect(() => {
    const cachedData = sessionStorage.getItem("popupData");

    if (cachedData) {
      setPopupData(JSON.parse(cachedData));
    } else {
      footerNewsLetter();
    }
  }, []);
  useEffect(() => {
    if (newsLetterContent && newsLetterContent.length > 0) {
      setPopupData(newsLetterContent);
      sessionStorage.setItem("popupData", JSON.stringify(newsLetterContent));
    }
  }, [newsLetterContent]);

  useEffect(() => window.scrollTo(0, 0), [pathname]);

  useEffect(() => {
    if (error) {
      push(routes.error.pattern);
    }
  }, [error]);

  useEffect(() => {
    if (token && !isLoggedIn) {
      refreshToken();
    }
  }, []);

  useEffect(() => {
    if ((!isLoggedIn && !token) || (isLoggedIn && token)) {
      fetchCinemas();
      getLocations();
      setVisibility(true);
    }
  }, [isLoggedIn, token]);

  // const cookiePolicyInfo = legalInfo.find(info => info.type === "cookiePolicy");

  useEffect(() => {
    if (Object.keys(newsLetter).length > 0) {
      sendFooterNewsletterRegistration(newsLetter);
    }
  }, [newsLetter]);

  useEffect(() => {
    if (newsLetterResult.sent === "true" || newsLetterResult.sent === true)
      setModal({ isVisible: true });
  }, [newsLetterResult]);

  useEffect(() => {
    if (user?.cardNumber?.length > 0) {
      fetchcode(user?.cardNumber);
    }
  }, [user, code]);

  return (
    <Fragment>
      <Wrapper isLoading={isLoading}>
        <Header
          cinemas={cinemas}
          locations={locations}
          savedLocation={savedLocation}
          isLoggedIn={isLoggedIn}
          userName={userName}
          isVisible={elementsVisibility.header}
          pathname={pathname}
        />
        {isVisible ? children : null}
        <Footer
          locations={locations}
          saveLocation={saveLocation}
          savedLocation={savedLocation}
          isVisible={elementsVisibility.footer}
          setNewsLetter={setNewsLetter}
          newsLetter={newsLetter}
          pathname={pathname}
          footerNewsLetter={footerNewsLetter}
          newsLetterContent={popupData?.length ? popupData : newsLetterContent}
          newsLetterResult={newsLetterResult}
          user={user}
          QRcode={QRcode}
        />
        <Toasters />
        {/* <CookieBot domainGroupId={domainGroupId} /> */}
        {/* {isRespondedToCookiesAgreement ? null : (
          <CookiesAgreement
            info={cookiePolicyInfo}
            acceptCookiesAgreement={acceptCookiesAgreement}
            declineCookiesAgreement={declineCookiesAgreement}
          />
        )} */}
        <MobileNav
          savedLocation={savedLocation}
          cinemas={cinemas}
          locations={locations}
          isLoggedIn={isLoggedIn}
          userName={userName}
          pathname={pathname}
          isVisible={elementsVisibility.footer}
        />
      </Wrapper>
      <Modal
        isSmall
        isOpen={modal.isVisible}
        close={() => setModal({ isVisible: false })}
        title={t("common.newsletter_popup_title")}
      >
        <ModalTypical
          iconId="complete"
          heading={t("common.newsletter_popup_heading")}
          message={t("common.newsletter_popup")}
        >
          <Button
            style={{ width: "200px" }}
            isPrimary
            onClick={() => {
              setModal({ isVisible: false });
            }}
          >
            {t("common.ok")}
          </Button>
        </ModalTypical>
      </Modal>
      {isLoading && <Loader />}
    </Fragment>
  );
};

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;
export default Layout;
