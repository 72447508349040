import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Svg } from 'app/components';
import useConfig from 'app/hooks/useConfig';
import { onHoverBehavior, onClickBehavior } from 'app/utils';
import LoginButton from './LoginButton';
import SignupButton from './SignupButton';
import UserDropdown from './UserDropdown';
import { SearchLink, BlockControlWrapper, LanguageSwitcher, SubList, SubListItem } from './styles';

const propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  userName: PropTypes.string
};
const defaultProps = {
  userName: null
};

const BlockControl = ({ isLoggedIn, userName }) => {
  const [, i18n] = useTranslation();
  const {
    routes,
    intl: { locales, defaultLocales }
  } = useConfig();


  useEffect(() => {
    const defaultLang = defaultLocales?.[0]?.locale ? defaultLocales?.[0]?.locale : i18n?.language;
    const currentLang = i18n.language;
    // console.log("defaultLang", defaultLang)
    const hasDefaultLanguageBeenSet = localStorage.getItem('hasDefaultLanguageBeenSet');

    if (!hasDefaultLanguageBeenSet && (!currentLang || currentLang !== defaultLang)) {
      i18n.changeLanguage(defaultLang);

      localStorage.setItem('hasDefaultLanguageBeenSet', 'true');
    }
  }, [i18n, defaultLocales]);
  const selectedLang = (i18n?.language).split('-')[0];
  const matchedLocale = locales.find((loc) => loc?.locale === selectedLang);

  // const selectedLang = i18n?.language === 'en-US' ? 'en' : i18n?.language === 'de-DE' ? 'de' : i18n?.language === 'de' ? 'de' : i18n?.language === 'en' && 'en';
  const changeLanguage = locale => () => {
    i18n.changeLanguage(locale);
    window.location.reload();
  };

  return (
    <BlockControlWrapper>
      <SearchLink
        to={routes.search.pattern}
        activeClassName="_active"
        isloggein={isLoggedIn === 'true' ? 'true' : 'false'}
      >
        <Svg id="search" />
      </SearchLink>

      {!isLoggedIn && <LoginButton />}

      {isLoggedIn ? <UserDropdown userName={userName} /> : <SignupButton />}
      {locales?.length > 1 ? (
        <LanguageSwitcher chevron {...onHoverBehavior}>
          <Svg id="language" />
          <span
          // className="_with-chevron"
          >
            {matchedLocale?.locale}
            {/* {selectedLang} */}
          </span>

          <SubList>
            {locales?.map(loc => (
              < SubListItem key={loc?.locale} {...onClickBehavior} >
                <button
                  type="button"
                  onClick={changeLanguage(loc?.locale)}
                  className={`l-sublist__link${matchedLocale?.locale === loc?.locale ? ' _active' : ''}`}
                // className={`l-sublist__link${selectedLang === loc?.locale ? ' _active' : ''}`}
                >
                  <span>{loc?.label}</span>
                  {matchedLocale?.locale === loc?.locale && <Svg id="checkmark" />}
                  {/* {selectedLang === loc?.locale && <Svg id="checkmark" />} */}
                </button>
              </SubListItem>
            ))}
          </SubList>
        </LanguageSwitcher>
      ) : null
      }
    </BlockControlWrapper >
  );
};

BlockControl.propTypes = propTypes;
BlockControl.defaultProps = defaultProps;
export default BlockControl;
