import BaseAPI from '../BaseAPI';

/**
 * Api class for handling user
 */
class User extends BaseAPI {
  /**
   * Creates User instance
   *
   * @param props {Object}
   * @return {User}
   */
  constructor(props) {
    super(props);

    this.baseUrl = '/users';
  }

  /**
   * Retrieves all user's transactions
   *
   * @return {Promise<*|Error>}
   */
  getTransactions() {
    const url = `${this.baseUrl}/balance/transactions`;

    return this.get({ url });
  }

  sendFormData(body) {
    const url = `/users/competition`;
    // const version = '/api/v1';

    return this.post({ url, body });
  }

  fetchCompetitionDetailContent(id) {
    const url = `/users/competition/${id}`;
    // const version = '/api/v1';

    return this.get({ url });
  }

  fetchBooking(params) {
    let url = ``;

    const type = params?.type;

    let payload = {};
    const bookingNumber = params?.bookingNumber;

    if (type === "bonuscard") {
      url = `/users/bookings/${bookingNumber}/payment-with-bonus-card`;
    } else if (type === "giftcard") {
      url = `/users/bookings/${bookingNumber}/payment-with-gift-card`;
      payload = params?.payload
    } else {
      url = `/users/bookings/${bookingNumber}/payment`;
    }
    // const version = '/api/v1';
    return this.post({ url, payload });
  }

  fetchGiftcardBooking(bookingNumber, payload) {
    const url = `/users/bookings/${bookingNumber}/payment-with-gift-card`;
    const body = payload;

    
return this.post({ url, body });
  }


  /**
   * Delete user account
   *
   * @returns {Promise<*|Error>}
   */
  removeAccount() {
    const url = this.baseUrl;

    return this.remove({ url });
  }
}

export default User;
