import styled from 'styled-components';
import { LineBordered, ShadowBoxDark } from 'app/components';
import { textTransform } from 'utils';


export const BlockTickets = styled(ShadowBoxDark).attrs({
  className: 'b-tickets'
})`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
`;



export const Info = styled.p.attrs({
  className: 'b-pickup__info'
})`
  color: ${({ theme }) => theme.color.textPrimary};
      align-items: center;
    margin-top: 0px;
    padding: 10.5px 0;
    // font-size: 1.8rem;
    font-size: 2.4rem;
    line-height: 0.8;
    border-top: 0;
`;

export const Page = styled.div`
  margin:${({ theme }) => theme.indent.m} 0px ;
  pointer-event:none


  &._seatplan {
    display: flex;
    flex-wrap: wrap;
  }

  .b-seatplan {
    width: calc(100% - 329px);
    padding-right: ${({ theme }) => theme.indent.l};
  }


  .b-modal-checkout-payment {
    text-align: right;

    .btn {
      min-width: auto;
    }
  }


  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    .b-seatplan {
      width: 100%;
      padding-right: 0;
    }
  }
`;