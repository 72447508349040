import BaseAPI from '../BaseAPI';

class Survey extends BaseAPI {
  constructor(props) {
    super(props);
    this.baseUrl = `/list?country=${process.env.REACT_APP_COUNTRY?.toUpperCase()}`;
  }

  /**
   * sends the survey inquiry
   * @returns {Promise<*|Error>}
   */

  fetchIframeData() {
    const url = `/landingpage-iframe${this.baseUrl}`;

    
return this.get({ url });
  }
}

export default Survey;