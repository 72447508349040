import { mergeMap, startWith, concatMap, catchError } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { setLoader, handleErrors, setError, setModal, createToast } from './deps';

import { GIFTCARDS_REQUEST, ADD_GIFTCARD_REQUEST, RESTRICTIONS_REQUEST, GIFT_CARD_LOCATION_REQUEST, CINEMAS_WITH_MOVIES_REQUEST_LOCATION, DATES_REQUEST, REQUEST_CONTENT_DATA, ADD_GIFTCARD_REQUEST_LOGOUT, ADD_GIFTCARD_REQUEST_TICKET } from './types';

import { giftcardsSuccess, addGiftcardSuccess, restrictionsSuccess, setGiftLocationInfoContent, setCinemasWithMoviesLocation, setDates, setContentData, addLogoutGiftcardSuccess, addTicketGiftcardSuccess } from './actions';

const fetchGiftcards = ($action, $state, { api }) => {
  const $apiGiftcards = api.getModuleByName('giftcards');

  return $action.pipe(
    ofType(GIFTCARDS_REQUEST),
    mergeMap(action =>
      from($apiGiftcards.getGiftcards()).pipe(
        concatMap(res => [giftcardsSuccess(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    )
  );
};

const addGiftcard = ($action, $state, { api }) => {
  const $apiGiftcards = api.getModuleByName('giftcards');

  return $action.pipe(
    ofType(ADD_GIFTCARD_REQUEST),
    mergeMap(action => {
      const {
        payload: { giftcardNumber, notifyForm, t, userRestrictions }
      } = action;

      const { allowedGiftCardNumber } = userRestrictions;
      const allowedRange = allowedGiftCardNumber?.split(";");

      return from($apiGiftcards.addGiftcard(giftcardNumber)).pipe(
        concatMap(res => {
          notifyForm();

          return [addGiftcardSuccess(res), setLoader(false)];
        }),

        ...handleErrors(action),
        catchError(err => {
          const { isAllowGiftCardNumber, allowedGiftCardNumber } =
            $state.value.giftcards.restrictions[0] || $state.value.sessions.session.restrictions;

          if (
            (isAllowGiftCardNumber || allowedGiftCardNumber) &&
            err.message.includes('Invalid giftcard number')
          ) {
            // notifyForm({ message: 'validationError' });
            if (!isAllowGiftCardNumber && giftcardNumber.length !== 16) {
              notifyForm({ message: t("common.giftcard_digit_error") });
            } else if (giftcardNumber.length === 16 && !allowedRange?.some(item =>
              giftcardNumber?.startsWith(item.replace("*", ""))
            )) {
              notifyForm({
                message: t("common.giftcard_number_error", {
                  what: allowedGiftCardNumber
                })
              });
            } else if (err.message.includes('giftcard has no value')) {
              notifyForm({ message: t("common.giftcard_has_no_value") });
            }

            return concat([setModal('errorConfirm', true), setLoader(false)]);
          }
          notifyForm({ message: err.message });
          
return of(setLoader(false));
        }),

        startWith(setLoader(true))
      );
    })
  );
};

const addGiftcardLogout = ($action, $state, { api }) => {
  const $apiGiftcards = api.getModuleByName('giftcards');

  return $action.pipe(
    ofType(ADD_GIFTCARD_REQUEST_LOGOUT),
    mergeMap(action => {
      const {
        payload: { giftcardNumber, notifyForm }
      } = action;

      return from($apiGiftcards.addGiftcardLogout(giftcardNumber)).pipe(
        concatMap(res => {
          notifyForm();

          return [addLogoutGiftcardSuccess(res), setLoader(false)];
        }),

        ...handleErrors(action),
        catchError(err => {
          const { isAllowGiftCardNumber, allowedGiftCardNumber } =
            $state.value.giftcards.restrictions[0] || $state.value.sessions.session.restrictions;

          if (
            (isAllowGiftCardNumber || allowedGiftCardNumber) &&
            err.message.includes('Invalid giftcard number')
          ) {
            notifyForm({ message: 'validationError' });

            return concat([setModal('errorConfirm', true), setLoader(false)]);
          }
          notifyForm({ message: err.message });

          return of(setLoader(false));
        }),

        startWith(setLoader(true))
      );
    })
  );
};

const addGiftcardTicket = ($action, $state, { api }) => {
  const $apiGiftcards = api.getModuleByName('giftcards');

  return $action.pipe(
    ofType(ADD_GIFTCARD_REQUEST_TICKET),
    mergeMap(action => {
      const {
        payload: { giftcardNumber, notifyForm, t, userRestrictions }
      } = action;

      const { allowedGiftCardNumber } = userRestrictions;
      const allowedRange = allowedGiftCardNumber?.split(";");

      return from($apiGiftcards.addGiftcardLogout(giftcardNumber)).pipe(
        concatMap(res => {
          notifyForm();

          return [addTicketGiftcardSuccess(res), setLoader(false)];
        }),

        ...handleErrors(action),
        catchError(err => {

          const { isAllowGiftCardNumber, allowedGiftCardNumber } =
            $state.value.giftcards.restrictions[0] || $state.value.sessions.session.restrictions;

          console.log("isAllowGiftCardNumber", isAllowGiftCardNumber);
          if (
            (isAllowGiftCardNumber || allowedGiftCardNumber) &&
            err.message.includes('Invalid giftcard number')
          ) {
            // notifyForm({ message: 'validationError' });
            console.log("giftcardNumber", giftcardNumber.length);
            if (isAllowGiftCardNumber && giftcardNumber.length !== 16) {
              notifyForm({ message: t("common.giftcard_digit_error") });
            } else if (giftcardNumber.length === 16 && !allowedRange.some(item =>
              giftcardNumber?.startsWith(item.replace("*", ""))
            )) {
              notifyForm({
                message: t("common.giftcard_number_error", {
                  what: allowedGiftCardNumber
                })
              });
            } else if (err.message.includes('giftcard has no value')) {
              notifyForm({ message: t("common.giftcard_has_no_value") });
            }

            return concat([setModal('errorConfirm', true), setLoader(false)]);
          }
          notifyForm({ message: err.message });
          
return of(setLoader(false));
        }),

        startWith(setLoader(true))
      );
    })
  );
};

const fetchRestrictions = ($action, $state, { api }) => {
  const $apiGiftcards = api.getModuleByName('giftcards');

  return $action.pipe(
    ofType(RESTRICTIONS_REQUEST),
    mergeMap(action =>
      from($apiGiftcards.getRestrictions()).pipe(
        mergeMap(res => concat([restrictionsSuccess(res), setLoader(false)])),
        ...handleErrors(action),

        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    )
  );
};

function fetchForCinemasWithMovies($action, $state, { api }) {
  const $api = api.getModuleByName('cinemas');


  return $action.pipe(
    ofType(CINEMAS_WITH_MOVIES_REQUEST_LOCATION),
    mergeMap(action => {
      return from($api.fetchWithMoviesLocation()).pipe(
        concatMap(cinemas => [setCinemasWithMoviesLocation(cinemas), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [createToast('warning', err.message), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}

const fetchLocationInfo = ($action, $state, { api }) => {
  const $apiGiftcards = api.getModuleByName('giftcards');

  return $action.pipe(
    ofType(GIFT_CARD_LOCATION_REQUEST),
    mergeMap(action =>
      from($apiGiftcards.getLocation()).pipe(
        mergeMap(res => concat([setGiftLocationInfoContent(res), setLoader(false)])),
        ...handleErrors(action),

        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    )
  );
};

function fetchDates($action, $state, { api }) {
  const $api = api.getModuleByName('movies');

  return $action.pipe(
    ofType(DATES_REQUEST),
    mergeMap(action => {
      const { cinemaId, location } = action.payload;

      return from($api.fetchDatesList({ cinemaId, location })).pipe(
        concatMap(dates => [setDates(dates), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}

function fetchContentData($action, $state, { api }) {
  const $api = api.getModuleByName('competitions');

  return $action.pipe(
    ofType(REQUEST_CONTENT_DATA),
    mergeMap(action => {

      return from($api.fetchGiftCardContent()).pipe(
        concatMap(dates => [setContentData(dates), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}



export { fetchGiftcards, addGiftcard, fetchRestrictions, fetchLocationInfo, fetchForCinemasWithMovies, fetchDates, fetchContentData, addGiftcardLogout, addGiftcardTicket };
