import styled from "styled-components";
import { linkNormal } from "app/theme";
import { textTransform } from "utils";
import memberLevel4 from '../../assets/images/memberLevel4.svg';
import logoSvgCap from '../../assets/images/logoSvgCap.svg';
import logoSvgCapWhite from '../../assets/images/logoSvgCapWhite.svg';

export const FooterTag = styled.footer.attrs({
  className: "footer"
})`
  ${({ isVisible }) => !isVisible && "display: none;"}
`;


export const BonusCardContainer = styled.div.attrs({
  className: "bonuscard_container"
})`
.bonuscardButton {
  width:56px;
  height:56px;
  position: fixed;
  bottom: 65px;
  right: 16px;
}
`

export const PopUpButton = styled.div`
  background-color: #de1619;
  color: white;
  padding: 10px 16px;
  border: none;
  cursor: pointer;
  position: absolute;
  border-radius: 0px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  rotate: 180deg;
`;

export const BlockMainWrapper = styled.div.attrs({
  className: "footer__main-wrapper"
})`
  padding: ${({ theme }) => theme.indent.l} 0;
  background-color: ${({ theme }) => theme.color.bgSecondary};

  .offPopup {
    margin-right: -448px;
    // margin-bottom: -140px;
  }

  .popup-container {
    width: 500px;
    height: 350px;
    position: fixed;
    bottom: 2%;
    right: 0%;
    z-index: 10;
    display: flex;
    flex-direction: row;
  }

  .kBRqrY {
    background-color: #de1619;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    margin-top: 89px;
    margin-left: -84px;
    position: absolute;
    border-radius: 0px;
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  .fotterTop .newsLetterPosition .showPopUp .divMain{
    display: none;
  }
  
  .fotterTop {
    width: 100%;
    display: block;
    position: relative;

    .news-latter-popup-container {
      position: relative;
    }
    .newsLetterPosition {
      display: flex;
      flex-direction: row;
      width: 100%;
      bottom: 100%;

      .newsPopup.showPopUp {
        display: block;
      }
      .newsPopup {
        max-width: 450px;
        width: 100%;
        right: 0;
        position: absolute;
        background: #191919;
      }
      .newsBtn {
        background-color: #de1619;
        z-index: 100;
        color: white;
        // padding: 16px 20px;
        border: none;
        cursor: pointer;
        margin-top: 58px;
        margin-left: -4px;
        border-radius: 0px;
        -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        transform: rotate(270deg);
      }
    }
  }
  .ReactModal__Content--after-open {
    position: relative;
    inset: 50% auto auto 50%;
    border: 0px;
    background: rgb(36, 36, 36);
    overflow: auto;
    outline: none;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    max-width: 480px;
    margin: 30px 0px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 16px 0px;
    transform: translate(-50%, -50%);
    padding: 40px;
    border-radius: 0px;
  }

  @media screen and (max-width: 2000px) and (min-width: 480px) {
    .kBRqrY {
      display: block;
    }
   .newsBtnDe{
    margin-top: 87.4px !important;
    margin-left: -83px !important;
    }

    .offButton{
      bottom: -5%;
    }

    .newsPopup {
      max-width: 450px;
      height: 385px;
      }
    }
  }

  @media screen and (max-width: 992px) and (min-width: 480px) {
    .offPopup {
      margin-right: -450px;
      // margin-bottom: -140px;
    }
    .offButton{
      /* bottom: 10%; */
      bottom: 13%;
    }
    .popup-container {
      width: 500px;
      height: 390px;
    }
  }

  @media screen and (max-width: 479px) and (min-width: 360px) {
    .offPopup {
      margin-right: 0px;
    }
    .newsBtn{
      display: none;
    }
    .mobilePopup {
      margin-right: -450px;
    }
    .popup-container {
      top: 20%;
    }

    .fotterTop .newsLetterPosition .newsPopup {
      max-width: 350px;
      width: 100%;
      right: 0;
      position: absolute;
      background: #191919;
    }

    .kBRqrY {
      display: none;
    }
  }

  @media screen and (max-width: 479px) and (min-width: 150px) {
    .mobilePopup {
      margin-right: -450px;
    }
    .offPopup {
      margin-right: 0px;
    }
    .popup-container {
      top: 20%;
    }

    .offPopup.popup-container {
      display: block;
    }
    .popup-container.mobilePopup {
      display: none;
    }
    .fotterTop .newsLetterPosition .newsPopup {
      position: fixed !important;
      padding: 20px 10px
      width: 90%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #191919;
    }
  }
`;

export const BlockMainLinks = styled.div.attrs({
  className: "footer__main-links"
})`
  display: flex;
  justify-content: space-between;
  margin: 0 -8px;

  & > .footer__main-inner,
  .footer__main-inner-wrapper,
  .footer__main-inner-input-wrapper {
    padding: 0 16px;
  }

  .footer__main-links-title {
    /* font: 700 1.6rem/1.44 Cabin, sans-serif; */
    font: 700 1.6rem/1.44 'Open Sans', sans-serif;
    text-transform: ${textTransform};
    color: ${({ theme }) => theme.color.textPrimary};
  }

  .footer__main-links-subtitle {
    width: 358px;
    font-weight: 390;
    font-size: 16px;
    line-height: 20px;
    margin-top: 10px;
    color: ${({ theme }) => theme.color.textSecondary};
  }

  .footer__main-inner-sub {
    margin-bottom: 28px;   
  }

  .footer__main-inner-newsLetter-wrapper {
    width: 374px;
    position: relative;
    margin-top: 7px;
  }

  .footer__main-inner-newsLetter-input {
    padding-right: 125px;
  }

  .footer__main-inner-newsLetter-subscribe {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    border-radius: 0 2em 2em 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    flex-direction: column;

    & > .footer__main-inner:not(._store-links) {
      display: none;
    }

    & > .footer__main-inner._store-links {
      width: 100.3333%;
      text-align: center;

      .footer__main-links-title {
        margin-top: 16px;
      }
    }

    .footer__main-inner-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 33px;
      margin-top: 26px;
      padding: 0 18px;
    }

    .footer__main-inner-sub {
      margin-top: 0;
    }

    .footer__main-inner-newsLetter-wrapper {
      width: 100%;
    }
  }
  @media (max-width: 441px) {
    .footer__main-links-subtitle {
      width: 100%;
      font-size: 15px;
      line-height: 180%;
    }
  }
`;

export const ListLinks = styled.ul`
  margin-top: 10px;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    ${({ withSvg }) => withSvg && 'display: flex; justify-content: center;'}
  }
`;

export const ListLinksItem = styled.li`
  display: block;
  ${({ withSvg }) => withSvg && 'font-size: 0;'}

  & + & {
    margin: ${({ withSvg, theme }) => (withSvg ? theme.indent.m : theme.indent.s)} 0 0 0;
  }

  .l-footer-main__link {
    ${linkNormal}

    & > .svg-appstore,
    & > .svg-playstore {
      width: 161px;
      height: 48px;
      transition: box-shadow 0.2s ease-out, transform 0.2s ease-out;

      &:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.7);
        transform: translateY(-3px);
      }
    }

    & > ._button-white {
      transition: box-shadow 0.2s ease-out, transform 0.2s ease-out;

      &:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.7);
        transform: translateY(-3px);
      }
    }
  }

  .l-footer-main__link._active {
    pointer-events: none;
    color: ${({ theme }) => theme.color.textPrimary};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    display: block;

    & + & {
      margin: ${({ withSvg, theme }) => (withSvg ? `0 0 0 ${theme.indent.m}` : `8px 0 0 0`)};
    }

    .l-footer-main__link {
      & > .svg-appstore,
      & > .svg-playstore {
        width: 134px;
        height: 40px;
      }
    }
  }
`;

export const BlockSecondaryWrapper = styled.div`
  padding: 20px 0;
  background-color: ${({ theme }) => theme.color.bgPrimary};
  color: ${({ theme }) => theme.color.textSecondary};

  & > .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (max-width: 1200px) {
    & > .container {
      text-align: center;
    }

    .footer__secondary-copyright {
      order: 1;
      width: 100%;
      margin-top: ${({ theme }) => theme.indent.m};
    }

    .b-contacts {
      width: 100%;
    }

    .l-social {
      width: 100%;
      margin: 0 auto ${({ theme }) => theme.indent.m};
    }
  }
  @media screen and (max-width: 999px ){
    height: 150px;
  }
  @media screen and (max-width: 734px){
   height: 220px;
  }
`;

export const Copyright = styled.div.attrs({
  className: 'footer__secondary-copyright'
})`
  /* font-size: 1.5rem; */
  font-size: 1.3rem;
  line-height: 1.33;
  font-weight: 500;
`;

export const Contacts = styled.div.attrs({
  className: 'b-contacts'
})`
  .b-contacts__link {
    ${linkNormal};
  }
  font-weight:500;
  .b-contacts__link + .b-contacts__link {
    margin: 0 0 0 24px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    .b-contacts__link {
      display: block;
    }

    .b-contacts__link + .b-contacts__link {
      margin: 8px 0 0 0;
    }
  }
`;

export const LocationItem = styled.div`
  cursor: pointer;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  text-transform: ${textTransform};
  color: #FFFFFF;
  // padding: 40px;
  max-width: 80%;
  margin: 0 auto;
  margin-bottom: 30px;

  @media screen and (max-width: 359px) and (min-width: 320px){
    font-size: 16px;
  }
`;

export const PopupContent = styled.div`
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: 390;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #A1A1A1;
  
  @media screen and (max-width: 359px) and (min-width: 320px){
    font-size: 14px;
  }
`;

export const NewsLetterForm = styled.div`
    max-width: 60%;
    margin: 0 auto;
    button.footer__main-inner-newsLetter-subscribe {
    margin-top: 20px;
    width: 100%;
  }
  
  @media screen and (max-width: 480px) and (min-width: 150px){
    max-width: 80%;
  }
`;

export const DivMain = styled.div`
  padding: 20px 10px 28px 10px;
  // min-width:288px;
  @media screen and (max-width: 480px) and (min-width: 150px){
    padding: 5px 5px;
  }
`;

export const CloseBtn = styled.button.attrs({
  type: 'button'
})`
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;

  & > .svg-close {
    display: block;
    fill: ${({ theme }) => theme.color.textPrimary};
    width: 24px;
    height: 24px;
    transition: opacity 0.2s ease-out;
    opacity: 0.8;
    backface-visibility: hidden;
  }

  &:hover > .svg-close {
    opacity: 1;
  }
`;

export const PopUpForm = styled.div``;

export const CardContainer = styled.div`
  display: flex;
  flex-direction:column;

  .otherCard {
    margin-top: 45px !important;
  }

  .commanCss {
    height: 100%;
    width: 50%;
    position: absolute;
    z-index: 9;
    background-repeat: no-repeat;
    left: 43%;
    bottom: 3%;
    transform: rotate(270deg);

    @media screen and (max-width: 414px) and (min-width: 376px) {
      left: 45%;
      bottom: 0%;
    }
    @media screen and (max-width: 375px) and (min-width: 361px) {
      left: 46%;
      bottom: -2%;
    }
    @media screen and (max-width: 360px) and (min-width: 321px) {
      left: 48%;
      bottom: -2%;
    }
    @media screen and (max-width: 320px){
      left: 50%;
      bottom: -4%;
    }
  }

  .logoSvgCap {
    background-image: url(${logoSvgCap});
  }

  .logoSvgCapWhite{
    background-image: url(${logoSvgCapWhite});
  }

  .memberLevel_4 {
    width: 380px;
    height: 230px;
    margin-top: 40px;
    padding: 10px 16px;
    display: inline-block;
    background-image: url(${memberLevel4});
    background-size: cover;
    box-shadow: 0px 3px 4px rgba(0,0,0,0.597515);
    border-radius: 8px;
    rotate: 270deg;
    position: relative;
  }

  .memberLevel_3 {
    width: 370px;
    height: 230px;
    margin-top: 40px;
    padding: 10px 16px;
    display: inline-block;
    background-size: cover;
    box-shadow: 0px 3px 4px rgba(0,0,0,0.597515);
    border-radius: 8px;
    rotate: 270deg;
    position: relative;
    background-color: ${({ theme }) => theme.color.bgPrimary}; 
  }

  .memberLevel_2 {
    width: 370px;
    height: 230px;
    margin-top: 40px;
    padding: 10px 16px;
    display: inline-block;
    background-size: cover;
    box-shadow: 0px 3px 4px rgba(0,0,0,0.597515);
    border-radius: 8px;
    rotate: 270deg;
    position: relative;
    background-color: ${({ theme }) => theme.color.bgRed};
  }
`;

export const Card = styled.div`
  width:338px;
  height:216px;
  border-radius:16px;
  padding:12px 14px;;
  transform: rotate(270deg);
  position: absolute;
  margin-top:60px;

  .barcode-container {
    margin-top:70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    background-color: white;
    border-radius: 6px;
    gap:2px;
    width:155px;
    margin-left:85px;
    height:131px;
  }

  .barcode-container .barcode{
  background-color:white;
  margin-top:4px;
}

  .barcode-container .barcode img { 
    max-width:100px;
  }

  .username {
    margin:2px 0px;
    text-align: left;
  }

  .black {
    color: #191919 !important;
  }

  .barcode-container .barcode-number {
    color: black;
  }
`;

export const CardImg = styled.div`
  position:absolute;
  margin-top: -63px;
  margin-left:54px;

  .barcode-container{
    display: none;
  }
`;