/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable array-callback-return  */
/* eslint-disable jsx-a11y/anchor-is-valid  */
/* eslint-disable func-names */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import storage from 'app/services/session';
import useUtils from 'app/hooks/useUtils';

export default function (order, isRedemption = false) {
  const { session, wizard } = useSelector(state => state.sessions);
  const { 
    isBonus,
    // isGiftCardApplied 
  } = wizard;
  const payment = useSelector(state => state.payment.payment);
  const paymentReserved = useSelector(state => state.user.reservedBooking);
  const { getDateWithOffset } = useUtils();

  if(order?.bonusCardBound) {
    return;
  }

  useEffect(() => {
    if (payment !== null && payment !== undefined) {
      const { paymentPageUrl, pingTimeoutSecs, pingTimes } = payment;

      let orderPaymentTTL = "";

      if (payment?.restrictions) {
        orderPaymentTTL = payment?.restrictions?.orderPaymentTTL;
      } else {
        orderPaymentTTL = session?.restrictions?.originalRestrictions;
      }
      storage.setOrder(order);
      storage.setPayment(getDateWithOffset(), orderPaymentTTL, pingTimeoutSecs, pingTimes);
      if (!isBonus) {
        window.addEventListener('unload', () => { });
        window.onpageshow = function (event) {
          if (event.persisted) {
            window.location.reload();
          }
        };
        window.location.href = paymentPageUrl;
      }
    }  else if (Object.keys(paymentReserved).length > 0) {
      const { paymentPageUrl, pingTimeoutSecs, pingTimes } = paymentReserved;
    
      const orderPaymentTTL = "";
      // if(paymentReserved?.restrictions){
      //   orderPaymentTTL = paymentReserved?.restrictions?.orderPaymentTTL;
      // }else{
      //   orderPaymentTTL = paymentReserved?.restrictions?.originalRestrictions;
      // }
      // storage.setOrder(order);

      storage.setPayment(getDateWithOffset(), orderPaymentTTL, pingTimeoutSecs, pingTimes);
      // if (!isBonus && !isGiftCardApplied) {
      //   window.addEventListener('unload', () => {});
      //   window.onpageshow = function(event) {
      //     if (event.persisted) {
      //       window.location.reload();
      //     }
      //   };
      // }
      window.location.href = paymentPageUrl;
    }

    storage.setBookingDetails(isRedemption);
  }, [payment, order, isRedemption, getDateWithOffset, paymentReserved]);
}
