import { SESSIONS_TICKET_SUCCESS, SESSIONS_BOOKING_COUNT_SUCCESS, SESSIONS_SEAT_PLAN_SUCCESS, CLEAR_SESSEION_SEAT_PLAN } from "./types";

const initialState = {
  sessionsPassData: [],
  sessionsBookingCount: [],
  plan: null,
}


export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SESSIONS_TICKET_SUCCESS: {
      return {
        ...state,
        sessionsPassData: payload || [],
      };
    }
    case SESSIONS_BOOKING_COUNT_SUCCESS: {
      return {
        ...state,
        sessionsBookingCount: payload?.bookingCount || [],
      };
    }
    case SESSIONS_SEAT_PLAN_SUCCESS: {
      const { plans } = payload;
      const { rows, icons } = plans;
      const newStatePlan = icons?.length
        ? plans
        : {
          ...state.plan,
          rows
        };

      
return {
        ...state,
        plan: newStatePlan
      };
    }
    case CLEAR_SESSEION_SEAT_PLAN: {
      return {
        ...state,
        plan: null
      };
    }

    default: {
      return state;
    }
  }
}