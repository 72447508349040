import { connect } from 'react-redux';
import PickupScreen from './PickupScreen.component';
import concessionsModule from 'app/store/concessions';

const { pickUpNumberRequest, setpickUpNumber } = concessionsModule.actions;

const mapStateToProps = () => ({

});
const mapDispatchToProps = {
  pickUpNumber: pickUpNumberRequest,
  setPickUpNumber: setpickUpNumber
};
export default connect(mapStateToProps, mapDispatchToProps)(PickupScreen);
