import { mergeMap, startWith, concatMap, catchError } from 'rxjs/operators';
import { forkJoin, from } from 'rxjs';
import { ofType } from 'redux-observable';
import { setError } from 'app/store/cinemas/deps';

import {
  setLoader,
  setErrorApi,
  separateErrors,
  authErrorHandleAfterManualCatch,
  handleErrors
} from './deps';

import {
  successMoviesRequest,
  setUpcomingMovies,
  setDaysSessions,
  setMovie,
  setDateList,
  setSessions,
  setAdvertisementData,
  setWebEventData,
  moviesFilterSuccess,
} from './actions';

import {
  TOP_MOVIES_REQUEST,
  NOW_PLAYING_MOVIES_REQUEST,
  UPCOMING_MOVIES_REQUEST,
  MOVIE_REQUEST,
  DATE_LIST_REQUEST,
  SESSIONS_REQUEST,
  DAYS_SESSIONS_REQUEST,
  EVENTS_SESSIONS_REQUEST,
  ADVERTISEMENT_REQUEST,
  NEW_SESSIONS_REQUEST,
  SET_NEW_SESSIONS,
  EVENT_WEB_SEND,
  MOVIES_FILTER,
} from './types';

import { cinemasDailySessions } from './utils';

function fetchTopMovies($action, $state, { api }) {
  const $api = api.getModuleByName('movies');

  return $action.pipe(
    ofType(TOP_MOVIES_REQUEST),
    mergeMap(action => {
      const { params } = action.payload;

      return forkJoin(
        $api.fetchTop(params).catch(err => ({ err })),
        $api.fetchDatesList({ top: true }).catch(err => ({ err })),
        authErrorHandleAfterManualCatch.bind(null, (films, dates) => ({ films, dates }))
      ).pipe(
        concatMap(res => {
          const { data, errors, hasErrors } = separateErrors(res);

          return [
            successMoviesRequest(data),
            setErrorApi(hasErrors ? errors : null),
            setLoader(false)
          ];
        }),

        ...handleErrors(action),

        startWith(setLoader(true))
      );
    })
  );
}

function fetchNowPlayingMovies($action, $state, { api }) {
  const $api = api.getModuleByName('movies');

  return $action.pipe(
    ofType(NOW_PLAYING_MOVIES_REQUEST),
    mergeMap(action => {
      const { params } = action.payload;

      return forkJoin(
        $api.fetchNowPlaying(params).catch(err => ({ err })),
        $api.fetchDatesList().catch(err => ({ err })),
        authErrorHandleAfterManualCatch.bind(null, (films, dates) => ({ films, dates }))
      ).pipe(
        concatMap(res => {
          const { data, errors, hasErrors } = separateErrors(res);

          return [
            successMoviesRequest(data),
            setErrorApi(hasErrors ? errors : null),
            setLoader(false)
          ];
        }),

        ...handleErrors(action),

        startWith(setLoader(true))
      );
    })
  );
}

function fetchUpcomingMovies($action, $state, { api }) {
  const $api = api.getModuleByName('movies');

  return $action.pipe(
    ofType(UPCOMING_MOVIES_REQUEST),
    mergeMap(action => {
      const { params } = action.payload;

      return forkJoin(
        $api.fetchUpcoming(params).catch(err => ({ err })),
        $api
          .fetchMonthsList({
            comingSoon: true
          })
          .catch(err => ({ err })),
        authErrorHandleAfterManualCatch.bind(null, (films, upcomingDates) => ({
          films,
          upcomingDates
        }))
      ).pipe(
        concatMap(res => {
          const { data, errors, hasErrors } = separateErrors(res);

          return [
            setUpcomingMovies(data),
            setErrorApi(hasErrors ? errors : null),
            setLoader(false)
          ];
        }),

        ...handleErrors(action),

        startWith(setLoader(true))
      );
    })
  );
}

function fetchMovie($action, $state, { api }) {
  const $api = api.getModuleByName('movies');

  return $action.pipe(
    ofType(MOVIE_REQUEST),
    mergeMap(action => {
      const { id } = action.payload;

      return from($api.fetchMovieDetails(id)).pipe(
        concatMap(movie => [setMovie(movie), setLoader(false)]),

        ...handleErrors(action),
        catchError(() => [setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}

function fetchDateList($action, $state, { api }) {
  const $api = api.getModuleByName('movies');

  
return $action.pipe(
    ofType(DATE_LIST_REQUEST),
    mergeMap(action => {
      const { movieId, cinemaId } = action.payload;

      return from($api.fetchDatesList({ id: movieId, cinemaId })).pipe(
        concatMap(dates => [setDateList(dates), setLoader(false)]),

        ...handleErrors(action),
        catchError(() => [setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}

function fetchSessions($action, $state, { api }) {
  const $api = api.getModuleByName('movies');

  return $action.pipe(
    ofType(SESSIONS_REQUEST),
    mergeMap(action => {
      const { movieId, date } = action.payload;

      return from($api.fetchGroupedSessions(movieId, { date })).pipe(
        concatMap(sessions => [setSessions(sessions), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}
export const findMovies = ($action, $state, { api }) => {
  const $apiMovies = api.getModuleByName('movies');

  return $action.pipe(
    ofType(MOVIES_FILTER),
    mergeMap(action => {
      const { pathname, id, title } = action.payload;

      
return from($apiMovies.fetchShortURLMovies(pathname)).pipe(
        concatMap(movie => {
          if (movie?.message === 'Not Found') {
            return from($apiMovies.find(title)).pipe(
              concatMap(finalMovie =>
                [
                  moviesFilterSuccess(finalMovie),
                  setLoader(false)
                ]),
              ...handleErrors(action),
              catchError(err => [
                setError(err.code, err.message, err),
                setLoader(false)
              ])
            );
          }
          
return [
            moviesFilterSuccess(movie),
            setLoader(false)
          ];
        }),
        ...handleErrors(action),
        catchError(err => [
          setError(err.code, err.message, err),
          setLoader(false)
        ]),
        startWith(setLoader(true))
      );
    })
  );
};

function fetchNewSessions($action, $state, { api }) {
  const $api = api.getModuleByName('movies');

  return $action.pipe(
    ofType(NEW_SESSIONS_REQUEST),
    mergeMap(action => {
      const { movieId, eventId } = action.payload;

      return from($api.fetchNewGroupedSessions(movieId, eventId)).pipe(
        concatMap(newSessions => [SET_NEW_SESSIONS(newSessions), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}

function fetchAllDatesSessions($action, $state, { api }) {
  const $api = api.getModuleByName('movies');
  const $apiCinemas = api.getModuleByName('cinemas');

  return $action.pipe(
    ofType(DAYS_SESSIONS_REQUEST),
    mergeMap(action => {
      const { movieId, location } = action.payload;
      const data = {
        location,
      }

      return forkJoin(
        $api.fetchAllDatesSessions(movieId, data).catch(err => ({ err })),
        $apiCinemas.fetchAll().catch(err => ({ err })),
        authErrorHandleAfterManualCatch.bind(null, (sessions, cinemas) => ({ sessions, cinemas }))
      ).pipe(
        concatMap(res => {
          const { data, errors, hasErrors } = separateErrors(res);

          
return [
            setDaysSessions(cinemasDailySessions(data?.sessions, data?.cinemas)),
            setErrorApi(hasErrors ? errors : null),
            setLoader(false)
          ];
        }),

        ...handleErrors(action),

        startWith(setLoader(true))
      );
    })
  );
}

function fetchEventsSessions($action, $state, { api }) {
  const $api = api.getModuleByName('events');

  return $action.pipe(
    ofType(EVENTS_SESSIONS_REQUEST),
    mergeMap(action => {
      const { eventId, movieId } = action.payload;

      return from($api.fetchMovieSessions(eventId, movieId)).pipe(
        concatMap(res => [setDaysSessions(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}

function fetchAdvertisementBanner($action, $state, { api }) {
  const $api = api.getModuleByName('technologies');

  return $action.pipe(
    ofType(ADVERTISEMENT_REQUEST),
    mergeMap(action => {
      return from($api.fetchAdvertisement()).pipe(
        concatMap(res => [setAdvertisementData(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}

function fetchWebEvent($action, $state, { api }) {
  const $api = api.getModuleByName('movies');

  return $action.pipe(
    ofType(EVENT_WEB_SEND),
    mergeMap(action => {
      const params = action.payload?.params;

      
return from($api.fetchWebEvents(params)).pipe(
        concatMap(res => [setWebEventData(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}

export {
  fetchTopMovies,
  fetchNowPlayingMovies,
  fetchUpcomingMovies,
  fetchMovie,
  fetchDateList,
  fetchSessions,
  fetchNewSessions,
  fetchAllDatesSessions,
  fetchEventsSessions,
  fetchAdvertisementBanner,
  fetchWebEvent
};
