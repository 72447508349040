import { CONCESSIONS_COMBO_ON_SERVER, CONCESSIONS_DATA_STORE, CONCESSIONS_ON_SERVER, CONCESSIONS_REMOVE, CONCESSIONS_REQUEST_CATEGORY, CONCESSIONS_REQUEST_COMBO, CONCESSIONS_REQUEST_COMBO_ITEM, CONCESSIONS_REQUEST_ITEM, CONCESSIONS_SUCCESS_CATEGORY, CONCESSIONS_SUCCESS_COMBO, CONCESSIONS_SUCCESS_COMBO_ITEM, CONCESSIONS_SUCCESS_COMBO_ON_SERVER, CONCESSIONS_SUCCESS_ITEM, CONCESSIONS_SUCCESS_ON_SERVER, PICKUP_NUMBER_REQUEST, PICKUP_NUMBER_SUCCESS } from "./types";



export const requestConcessionCategories = (cinemaId) => ({
  type: CONCESSIONS_REQUEST_CATEGORY,
  payload: {
    cinemaId
  }
});
export const successConcessionCategories = concessions => ({
  type: CONCESSIONS_SUCCESS_CATEGORY,
  payload: { concessions }
});
export const requestConcessionItem = (cinemaId, id) => ({
  type: CONCESSIONS_REQUEST_ITEM,
  payload: {
    cinemaId, id
  }
});

export const requestConcessionCombo = (cinemaId, id) => ({
  type: CONCESSIONS_REQUEST_COMBO,
  payload: {
    cinemaId, id
  }
});

export const requestConcessionComboItem = (cinemaId, id) => ({
  type: CONCESSIONS_REQUEST_COMBO_ITEM,
  payload: {
    cinemaId, id
  }
});

export const requestConcessionOnServer = (orderId, data) => ({
  type: CONCESSIONS_ON_SERVER,
  payload: {
    orderId, data
  }
});

export const requestConcessionComboOnServer = (orderId, concessionsData, data) => ({
  type: CONCESSIONS_COMBO_ON_SERVER,
  payload: {
    orderId, concessionsData, data
  }
});

export const requestConcessionRemove = (orderId) => ({
  type: CONCESSIONS_REMOVE,
  payload: {
    orderId
  }
});

export const setConcessionItem = item => ({
  type: CONCESSIONS_SUCCESS_ITEM,
  payload: {
    item
  }
});

export const setConcessionCombo = comboItem => ({
  type: CONCESSIONS_SUCCESS_COMBO,
  payload: {
    comboItem
  }
});

export const setConcessionComboItem = comboItemData => ({
  type: CONCESSIONS_SUCCESS_COMBO_ITEM,
  payload: {
    comboItemData
  }
});

export const setConcessionOnServer = concessionItem => ({
  type: CONCESSIONS_SUCCESS_ON_SERVER,
  payload: {
    concessionItem
  }
});

export const setConcessionComboOnServer = concessionComboItem => ({
  type: CONCESSIONS_SUCCESS_COMBO_ON_SERVER,
  payload: {
    concessionComboItem
  }
});

export const setConcessionData = (normalSession, comboSession) => ({
  type: CONCESSIONS_DATA_STORE,
  payload: {
    normalSession,
    comboSession,
  }
});

export const pickUpNumberRequest = (cinemaId, bookingNumber, pickup) => ({
  type: PICKUP_NUMBER_REQUEST,
  payload: { cinemaId, bookingNumber, pickup }
});

export const setpickUpNumber = pickUpNumber => ({
  type: PICKUP_NUMBER_SUCCESS,
  payload: { pickUpNumber }
});